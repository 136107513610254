import React, { useRef } from "react";
import { FiWifi } from "react-icons/fi";
import { IoLogoNoSmoking } from "react-icons/io";
import { TbAirConditioning } from "react-icons/tb";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import cift7 from "../../assets/images/oda2/cift7.jpg";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import HeaderToggle from "../../components/headerToggle/HeaderToggle";
import data from "../../components/services/index";
import "./RoomScreen.css";
import RoomCard from "./components/RoomCard.js";

function RoomScreen() {
  const imageRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];

  const screenWidth = window.innerWidth;

  const scrollToImage = (index) => {
    if (imageRefs[index].current) {
      imageRefs[index].current.scrollIntoView({
        behavior: "smooth",
      });
    }
    if (index === 0 || index === 3) {
      window.location.href = "/";
    }
    if (index === 4) {
      window.location.href = "/iletisim";
    }
  };

  return (
    <div className="App">
      <div>
        {screenWidth < 910 ? (
          <HeaderToggle headerRefIndex={scrollToImage} />
        ) : (
          <Header headerRefIndex={scrollToImage} />
        )}
      </div>
      <div>
        {screenWidth > 910 ? (
          <img src={cift7} className="room-header-detail-image" alt="" />
        ) : (
          <div className="room-header-min-screen"></div>
        )}{" "}
      </div>
      <div className="room-header-row">
        <h1 className="room-title">ODALARIMIZ</h1>
        <p className="room-explanation">
          Hoş Geldiniz! Otelimizdeki odalar, konforun, şıklığın ve huzurun
          mükemmel bir karışımını sunuyor. Tatiliniz veya iş seyahatiniz için
          ideal bir kaçış noktası olan odalarımız, konforlu yataklar ve özenle
          seçilmiş detaylarla donatıldı. Her odamızda modern olanaklarla
          donatılmış banyolar, ücretsiz Wi-Fi erişimi bulunmaktadır. Keyifli bir
          konaklama deneyimi için bizi tercih ettiğiniz için teşekkür ederiz.
          Sizleri ağırlamaktan mutluluk duyacağız!
        </p>
      </div>
      <div className="room-list">
        {data.rooms.map((room, index) => {
          if (index % 2 === 0) {
            return (
              <div className="room-row" key={index}>
                <div ref={imageRefs[index]}>
                  <RoomCard room={room} />
                </div>
                <div style={{ height: 10 }} />
                {data.rooms[index + 1] && (
                  <div ref={imageRefs[index + 1]}>
                    <RoomCard room={data.rooms[index + 1]} />
                  </div>
                )}
              </div>
            );
          }
          return null;
        })}
      </div>
      <div
        style={{
          height: 130,
        }}
      />
      <div className="icon-container">
        <div className="room-icon-row">
          <FiWifi fontSize={60} color=" #e01616" />
          <h3 className="icon-title">Wi-Fi</h3>
        </div>
        <div className="room-icon-row">
          <IoLogoNoSmoking fontSize={60} color=" #e01616" />
          <h3 className="icon-title">Sigara İçilmez</h3>
        </div>
        <div className="room-icon-row">
          <TbAirConditioning fontSize={60} color=" #e01616" />
          <h3 className="icon-title">Klima</h3>
        </div>
      </div>
      <div
        style={{
          height: 130,
        }}
      />
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default RoomScreen;
