import { Route, Routes } from "react-router-dom";
import AboutScreen from "./screen/AboutScreen/AboutScreen.js";
import ContactScreen from "./screen/ContactScreen/ContactScreen.js";
import Home from "./screen/Home/Home.js";
import RoomDetailScrenn from "./screen/RoomDetailScreen/RoomDetailScreen.js";
import RoomScreen from "./screen/RoomScreen/RoomScreen.js";

function App() {
  return (
    <div>
      {/* <Layout /> */}
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="odalar" exact element={<RoomScreen />} />
        <Route path="oda-detay" exact element={<RoomDetailScrenn />} />
        <Route path="iletisim" exact element={<ContactScreen />} />
        <Route path="hakkimizda" exact element={<AboutScreen />} />
      </Routes>
    </div>
  );
}

export default App;
