import React, { useRef, useState } from "react";
import { FiWifi } from "react-icons/fi";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoLogoNoSmoking,
} from "react-icons/io";
import { TbAirConditioning } from "react-icons/tb";
import { useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import HeaderToggle from "../../components/headerToggle/HeaderToggle";
import "./RoomDetailScreen.css";

function RoomDetailScrenn() {
  const imageRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];
  const screenWidth = window.innerWidth;
  const location = useLocation();
  const { room } = location.state;
  const sliderRef = useRef(null);
  const modalSliderRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [doubleClick, setDoubleClick] = useState(false);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const settingsModal = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
    if (modalSliderRef.current) {
      modalSliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
    if (modalSliderRef.current) {
      modalSliderRef.current.slickPrev();
    }
  };

  const openModal = (index) => {
    if (screenWidth < 520) {
      setModalOpen(true);
      setSelectedImage(index);
    }
    if (doubleClick) {
      setModalOpen(true);
      setDoubleClick(false);
      setSelectedImage(index);
    } else {
      setSelectedImage(index);
      setDoubleClick(true);
      setTimeout(() => setDoubleClick(false), 300);
    }
  };

  const imageIndex = (index) => {
    sliderRef.current.slickGoTo(index);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };

  const scrollToImage = (index) => {
    if (imageRefs[index].current) {
      imageRefs[index].current.scrollIntoView({
        behavior: "smooth",
      });
    }
    if (index === 0) {
      window.location.href = "/";
    }
    if (index === 1) {
      window.location.href = "/odalar";
    }
  };

  return (
    <div className="App">
      <div>
        <div>
          {screenWidth < 910 ? (
            <HeaderToggle headerRefIndex={scrollToImage} />
          ) : (
            <Header headerRefIndex={scrollToImage} />
          )}
        </div>
        <div>
          {screenWidth > 910 ? (
            <img
              src={room.image[1]}
              className="room-header-detail-image"
              alt=""
            />
          ) : (
            <div className="room-header-min-screen"></div>
          )}
        </div>
        <div className="room-detail-header-row">
          <p className="room-detail-title">{room.title}</p>
          <p className="room-detail-title">{room.roomType}</p>
        </div>
      </div>
      <div className="room-detail-info">
        {screenWidth > 519 && (
          <div className="room-detail-modal-button">
            <IoIosArrowBack
              fontSize={screenWidth < 909 ? "47px" : "58px"}
              onClick={handlePrev}
            />
          </div>
        )}
        <div className="room-detail-slider">
          <Slider ref={sliderRef} {...settings} arrows={false}>
            {room.image.map((imageUrl, index) => (
              <div key={index} onClick={() => openModal(index)}>
                <img
                  src={imageUrl}
                  alt={`slide-${index}`}
                  className="room-detail-image"
                  style={{
                    width: "100%",
                  }}
                />
              </div>
            ))}
          </Slider>
        </div>
        {screenWidth > 519 && (
          <div className="room-detail-modal-button">
            <IoIosArrowForward
              fontSize={screenWidth < 909 ? "47px" : "58px"}
              onClick={handleNext}
            />
          </div>
        )}
      </div>
      <div className="room-detail-images-slider">
        {room.image.map((imageUrl, index) => (
          <div key={index} onClick={() => imageIndex(index)}>
            <img
              src={imageUrl}
              alt={`slide-${index}`}
              className="room-detail-images-slider-image"
            />
          </div>
        ))}
      </div>

      <p className="room-detail-explanation">{room.explanationRoom}</p>
      {screenWidth > 519 ? (
        <div className="room-detail-features">
          {room.features.map((feature, index) => {
            if (index % 3 === 0) {
              return (
                <div className="room-detail-feature-row" key={index}>
                  <div>
                    <p className="room-detail-feature-text">• {feature}</p>
                  </div>
                  <div
                    style={{
                      height: 15,
                    }}
                  />
                  {room.features[index + 1] && (
                    <div>
                      <p className="room-detail-feature-text">
                        • {room.features[index + 1]}
                      </p>
                    </div>
                  )}
                  <div
                    style={{
                      height: 15,
                    }}
                  />
                  {room.features[index + 2] && (
                    <div>
                      <p className="room-detail-feature-text">
                        • {room.features[index + 2]}
                      </p>
                    </div>
                  )}
                </div>
              );
            }
            return null;
          })}
        </div>
      ) : (
        <div className="room-detail-features">
          {room.features.map((feature, index) => (
            <div key={index} className="room-detail-feature-row">
              <p className="room-detail-feature-text">• {feature}</p>
            </div>
          ))}
        </div>
      )}
      <div
        style={{
          height: 130,
        }}
      />
      <div className="icon-container">
        <div className="room-icon-row">
          <FiWifi fontSize={60} color=" #e01616" />
          <h3 className="icon-title">Wi-Fi</h3>
        </div>
        <div className="room-icon-row">
          <IoLogoNoSmoking fontSize={60} color=" #e01616" />
          <h3 className="icon-title">Sigara İçilmez</h3>
        </div>
        <div className="room-icon-row">
          <TbAirConditioning fontSize={60} color=" #e01616" />
          <h3 className="icon-title">Klima</h3>
        </div>
      </div>
      <div
        style={{
          height: 130,
        }}
      />
      {modalOpen && (
        <div>
          <div className="room-detail-modal">
            {screenWidth > 519 && (
              <div className="room-detail-modal-button">
                <IoIosArrowBack
                  fontSize={screenWidth < 909 ? "47px" : "58px"}
                  onClick={handlePrev}
                />
              </div>
            )}
            <span className="room-detail-modal-close" onClick={closeModal}>
              &times;
            </span>
            <div className="room-detail-modal-content">
              <Slider
                ref={modalSliderRef}
                {...settingsModal}
                arrows={false}
                initialSlide={selectedImage}
              >
                {room.image.map((imageUrl, index) => (
                  <div key={index}>
                    <img
                      src={imageUrl}
                      alt={`slide-${index}`}
                      className="room-detail-modal-image"
                    />
                  </div>
                ))}
              </Slider>
            </div>
            {screenWidth > 519 && (
              <div className="room-detail-modal-button">
                <IoIosArrowForward
                  fontSize={screenWidth < 909 ? "47px" : "58px"}
                  onClick={handleNext}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
}

export default RoomDetailScrenn;
