import React, { useState } from "react";
import { FiInstagram } from "react-icons/fi";
import timya from "../../assets/logo.png";
import data from "../services";
import "./Header.css";

const Header = ({ headerRefIndex }) => {
  const pathname = window.location.pathname;

  const [selected, setSelected] = useState(
    pathname === "/odalar" || pathname === "/oda-detay"
      ? 1
      : pathname === "/iletisim"
      ? 4
      : pathname === "/hakkimizda"
      ? 2
      : 0
  );
  const handleTabClick = (index) => {
    if (index === 1) {
      window.location.href = "/odalar";
    } else if (index === 4) {
      window.location.href = "/iletisim";
    } else if (index === 2) {
      window.location.href = "/hakkimizda";
    } else if (index === 3) {
      if (pathname !== "/") {
        window.location.href = "/?scroll=gallery";
      } else {
        headerRefIndex(3);
      }
    } else {
      headerRefIndex(index ? index : 0);
    }
    setSelected(index);
  };

  return (
    <div className="header-container">
      <div className="name-row">
        {/* <h1 className="hotel-name">TİMYA</h1>
        <h3 className="hotel">Otel</h3> */}
        <img src={timya} className="timya-image" alt="" />
      </div>

      <div className="header-title-container">
        {data.tabs.map((item, index) => (
          <div className="header-title-row" key={index}>
            <p
              className={
                selected === index ? "header-title-selected" : "header-title"
              }
              onClick={() => handleTabClick(index)}
            >
              {item}
            </p>
          </div>
        ))}
      </div>
      <div className="icon-row">
        <FiInstagram 
          className="icon" 
          onClick={() => window.open('https://www.instagram.com/timyahotel?igsh=MXR1M2JmeDhtMW5nNw==', '_blank')}
          style={{ cursor: 'pointer' }}
        />
      </div>
    </div>
  );
};

export default Header;
