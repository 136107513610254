import React, { useRef } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import data from "../services";
import "./Rooms.css";

const Rooms = () => {
  const screenWidth = window.innerWidth;
  const navigate = useNavigate();

  const sliderRef = useRef(null);
  const settings = {
    infinite: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  <Slider ref={sliderRef} {...settings}>
    {data.images.map((imageUrl, index) => (
      <div key={index}>
        <img
          src={imageUrl}
          alt={`slide-${index}`}
          className="image"
          style={{ width: "95%" }}
        />
      </div>
    ))}
  </Slider>;

  const handleRoomDetailClick = (item) => {
    navigate("/oda-detay", { state: { room: item } });
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {data.rooms.map((item, index) => (
        <div className="rooms-container">
          {screenWidth < 520 ? (
            <>
              <div className="rooms-title-row-left">
                <h1 className="rooms-title">{item.title}</h1>
              </div>
              {item.features.map((feature, index) => (
                <p className="rooms-expectation">{feature}</p>
              ))}
              <div className="buttons-row-flex">
                <p
                  className="rooms-button"
                  onClick={() => handleRoomDetailClick(item)}
                >
                  İncele
                </p>
              </div>

              <div>
                <Slider ref={sliderRef} {...settings}>
                  {item.image.map((imageUrl, index) => (
                    <div key={index}>
                      <img
                        src={imageUrl}
                        alt={`slide-${index}`}
                        className="rooms-left"
                      />
                    </div>
                  ))}
                </Slider>
              </div>
            </>
          ) : item.aspect === "left" ? (
            <>
              <div className="rooms-image-container">
                <img src={item.image[0]} className="rooms-image-left" alt="" />
                <img src={item.image[1]} className="rooms-image-right" alt="" />
              </div>
              <div className="rooms-title-row">
                <h1 className="rooms-title">{item.title}</h1>
                <div
                  style={{
                    width: "12px",
                    height: "5px",
                    backgroundColor: "black",
                  }}
                />
                {item.features.map((feature, index) => (
                  <p className="rooms-expectation">{feature}</p>
                ))}
                <p
                  className="rooms-button"
                  onClick={() => handleRoomDetailClick(item)}
                >
                  İncele
                </p>
              </div>
            </>
          ) : (
            <>
              <div className="rooms-title-row-left">
                <h1 className="rooms-title">{item.title}</h1>
                <div
                  style={{
                    width: "12px",
                    height: "5px",
                    backgroundColor: "black",
                  }}
                />{" "}
                {item.features.map((feature, index) => (
                  <p className="rooms-expectation">{feature}</p>
                ))}{" "}
                <p
                  className="rooms-button"
                  onClick={() => handleRoomDetailClick(item)}
                >
                  İncele
                </p>
              </div>
              <div className="rooms-image-container">
                <img src={item.image[0]} className="rooms-left" alt="" />
                <img src={item.image[1]} className="rooms-right" alt="" />
              </div>
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default Rooms;
