import React from "react";
import data from "../services";
import "./Footer.css";

const Footer = () => {
  return (
    <div>
      <footer className="footer-container">
        <div className="footer-row">
          <div className="left-row">
            <h1 className="hotel-name">TİMYA</h1>
            <h3 className="hotel">Otel</h3>
          </div>
          <div className="text-row">
            <p className="text">{data.contact.address}</p>
          </div>
          <div>
            <p className="text">Telefon: {data.contact.telephone}</p>
            <p className="text">E-posta: {data.contact.email}</p>
          </div>
        </div>
        <div className="since-row">
          <p>&copy; 2024 ECK Tekno. Tüm hakları saklıdır.</p>
        </div>
      </footer>
      {/* <div
        style={{
          backgroundColor: "black",
          height: "90px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "#ffff",
        }}
      >
        <p>&copy; 2024 Örnek Otel. Tüm hakları saklıdır.</p>
      </div> */}
    </div>
  );
};

export default Footer;
