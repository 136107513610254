import React from "react";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const RoomCard = ({ room }) => {
  const navigate = useNavigate();

  const handleRoomDetailClick = () => {
    navigate("/oda-detay", { state: { room: room } });
    window.scrollTo(0, 0);
  };

  return (
    <div className="room-card-container">
      <img src={room.image[0]} className="room-card-image" alt="" />
      <div className="rooms-card-title-row">
        <h1 className="rooms-card-title">{room.title}</h1>
        <div
          style={{
            width: "100px",
            height: "3px",
            backgroundColor: "black",
          }}
        />
        <p>{room.display}</p>

        <p className="room-card-button" onClick={handleRoomDetailClick}>
          İncele
        </p>
      </div>
    </div>
  );
};

export default RoomCard;
