import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import emailjs from "emailjs-com";
import React, { useRef, useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import HeaderToggle from "../../components/headerToggle/HeaderToggle";
import "./ContactScreen.css";

const ContactScreen = () => {
  const imageRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];
  const screenWidth = window.innerWidth;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDD_iu8Caz1gB9VuziJmeIzy12i383FCuc",
  });
  const center = {
    lat: 40.992583,
    lng: 29.0259958,
  };

  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      name,
      surname,
      phone,
      message,
      email,
    };

    emailjs
      .send(
        "service_nrc71fb",
        "template_ndptx1a",
        templateParams,
        "dJZ-MViJKu4MPRfu1"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setShowSuccessMessage(true);
          setTimeout(() => {
            setShowSuccessMessage(false);
          }, 5000);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );

    setName("");
    setSurname("");
    setPhone("");
    setMessage("");
    setEmail("");
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^\d{0,11}$/.test(value)) {
      setPhone(value);
    }
  };

  const scrollToImage = (index) => {
    if (imageRefs[index].current) {
      imageRefs[index].current.scrollIntoView({
        behavior: "smooth",
      });
    }
    if (index === 0 || index === 3) {
      window.location.href = "/";
    }
    if (index === 1) {
      window.location.href = "/odalar";
    }
    if (index === 2) {
      window.location.href = "/hakkimizda";
    }
  };

  const validateInput = (e) => {
    if (e.target.validity.valueMissing) {
      e.target.setCustomValidity("Bu alan zorunludur.");
    } else if (e.target.name === "email" && !e.target.validity.valid) {
      e.target.setCustomValidity("Geçerli bir e-posta adresi giriniz.");
    } else if (e.target.name === "phone" && !e.target.validity.valid) {
      e.target.setCustomValidity(
        "Lütfen 11 haneli bir telefon numarası giriniz."
      );
    } else {
      e.target.setCustomValidity("");
    }
  };
  const handleMarkerClick = () => {
    window.open(
      `https://www.google.com/maps/place/Timya+Hotel/@40.992583,29.0259538,21z/data=!4m20!1m10!3m9!1s0x14cab8690ce90e37:0xc51f13e40caeb9c0!2sTimya+Hotel!5m2!4m1!1i2!8m2!3d40.992576!4d29.025984!16s%2Fg%2F1hc3jnwsw!3m8!1s0x14cab8690ce90e37:0xc51f13e40caeb9c0!5m2!4m1!1i2!8m2!3d40.992576!4d29.025984!16s%2Fg%2F1hc3jnwsw?entry=ttu`
    );
  };
  return (
    <div>
      {showSuccessMessage && (
        <div className="success-message">
          <p>Mesajınız başarıyla gönderildi!</p>
          <p>En kısa sürede dönüş yapılacak.</p>
        </div>
      )}
      <div>
        {screenWidth < 910 ? (
          <HeaderToggle headerRefIndex={scrollToImage} />
        ) : (
          <Header headerRefIndex={scrollToImage} />
        )}
      </div>
      <div
        style={{
          height: screenWidth > 520 ? 150 : 75,
          borderBottomWidth: 15,
          borderBottomColor: "red",
        }}
      />
      {isLoaded ? (
        <GoogleMap
          mapContainerStyle={{ height: "600px", width: "100%" }}
          center={center}
          zoom={15}
          componentRestrictions={() => {
            return { country: "TR" };
          }}
        >
          <MarkerF
            position={center}
            onClick={handleMarkerClick}
            animation={4}
          />
        </GoogleMap>
      ) : (
        <></>
      )}
      <div className="contact-explanation-row">
        <h2 className="contact-title">Görüş ve Önerilerinizi Önemsiyoruz</h2>
      </div>
      {screenWidth > 520 ? (
        <form onSubmit={sendEmail}>
          <div className="contact-form-row">
            <div className="contact-form">
              <label>Adınız:</label>
              <input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="contact-input"
                name="name"
                onInvalid={validateInput}
                onInput={(e) => e.target.setCustomValidity("")}
              />
            </div>
            <div className="contact-size-box" />

            <div className="contact-form">
              <label>Soyadınız:</label>
              <input
                type="text"
                value={surname}
                onChange={(e) => setSurname(e.target.value)}
                required
                className="contact-input"
                name="surname"
                onInvalid={validateInput}
                onInput={(e) => e.target.setCustomValidity("")}
              />
            </div>
          </div>
          <div className="contact-form-row">
            <div className="contact-form">
              <label>E-posta adresiniz:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                className="contact-input"
                name="email"
                onInvalid={validateInput}
                onInput={(e) => e.target.setCustomValidity("")}
              />
            </div>
            <div className="contact-size-box" />
            <div className="contact-form">
              <label>Telefon numaranız:</label>
              <input
                type="text"
                value={phone}
                onChange={handlePhoneChange}
                required
                className="contact-input"
                maxLength="11"
                pattern="\d{11}"
                name="phone"
                onInvalid={validateInput}
                onInput={(e) => e.target.setCustomValidity("")}
              />
            </div>
          </div>

          <div className="contact-form">
            <label>Mesajınız:</label>
            <textarea
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
              className="contact-massage-input"
              name="message"
              onInvalid={validateInput}
              onInput={(e) => e.target.setCustomValidity("")}
            />
          </div>

          <button type="submit">Gönder</button>
        </form>
      ) : (
        <form onSubmit={sendEmail}>
          <label>Adınız:</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="contact-input"
            name="name"
            onInvalid={validateInput}
            onInput={(e) => e.target.setCustomValidity("")}
          />

          <label>Soyadınız:</label>
          <input
            type="text"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            required
            className="contact-input"
            name="surname"
            onInvalid={validateInput}
            onInput={(e) => e.target.setCustomValidity("")}
          />
          <label>E-posta adresiniz:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="contact-input"
            name="email"
            onInvalid={validateInput}
            onInput={(e) => e.target.setCustomValidity("")}
          />
          <label>Telefon numaranız:</label>
          <input
            value={phone}
            onChange={handlePhoneChange}
            required
            className="contact-input"
            maxLength="11"
            pattern="\d{11}"
            name="phone"
            onInvalid={validateInput}
            onInput={(e) => e.target.setCustomValidity("")}
          />

          <label>Mesajınız:</label>
          <textarea
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            className="contact-massage-input"
            name="message"
            onInvalid={validateInput}
            onInput={(e) => e.target.setCustomValidity("")}
          />

          <button type="submit">Gönder</button>
        </form>
      )}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default ContactScreen;
