import React, { useRef } from 'react';
import istanbul from '../../assets/istanbul.png';
import Footer from "../../components/footer/Footer";
import Header from '../../components/header/Header';
import HeaderToggle from '../../components/headerToggle/HeaderToggle';
import './AboutScreen.css';

const AboutScreen = () => {
  const imageRefs = [useRef(), useRef(), useRef(), useRef(), useRef()];
  const screenWidth = window.innerWidth;

  const scrollToImage = (index) => {
    if (imageRefs[index].current) {
      imageRefs[index].current.scrollIntoView({
        behavior: 'smooth',
      });
    }
    if (index === 0 || index === 3) {
      window.location.href = '/';
    }
    if (index === 1) {
      window.location.href = '/odalar';
    }
    if (index === 2) {
      window.location.href = '/hakkimizda';
    }
  };

  return (
    <div>
      <div>
        {screenWidth < 910 ? (
          <HeaderToggle headerRefIndex={scrollToImage} />
        ) : (
          <Header headerRefIndex={scrollToImage} />
        )}
      </div>
      <div
        style={{
          height: screenWidth > 520 ? 150 : 75,
          borderBottomWidth: 15,
          borderBottomColor: "red",
        }}
      />
      <img src={istanbul} className="about-image" alt="" />
      <div
        className="about-container"
      >
        <div className="about-section">
          <h1>Hakkımızda</h1>
          <p>
            Kadıköy otel sektöründe 2011 yılından bu yana kesintisiz hizmet veren
            Timya Otel, müşteri memnuniyetini, mükemmeliyetçi üslubu ile en üst
            düzeye taşımayı başarmıştır. Güler yüzlü hizmeti, konforlu odaları,
            şık ve çağdaş görünümdeki restore edilmiş binası, ışıklandırma
            sistemleri, İstanbul Boğazı'nın eşsiz manzarası ile buluşarak,
            Kadıköy otel piyasasında kendisine benzersiz bir yer edinmiştir.
          </p>

          <p>
            Ağırlayacağı müşterilerinin isteklerini gözeterek, 5 çeşit oda
            seçeneği hazırlayan Timya Otel, odalarında konforu yakalayabilmek
            adına her ayrıntıyı düşünmüştür. Tek yataklı 1 kişilik odaları, 2
            ayrı yataklı 2 kişilik odaları, 3 ayrı yataklı 3 kişilik odaları, 2
            kişilik 1 adet geniş çift kişilik yatağın bulunduğu odaları ve son
            olarak lüksün tadına varacağınız süit geniş, çift kişilik yataklı,
            jakuzili odaları mevcuttur. Müşteri memnuniyetini üst düzeyde tutmak
            adına odalarda her ayrıntı düşünülmüş, jakuzili odaları ile
            misafirlerinin tatillerini keyifli bir hale dönüştürmelerini sağlamayı
            amaçlamıştır.
          </p>
        </div>

        <div className="location-section">
          <h2>Konumumuz</h2>
          <div className="location-grid">
            <div className="location-item">
              <h3>Ulaşım</h3>
              <ul>
                <li>Avrupa yakasına deniz yolu ile 20 dakika</li>
                <li>Kadıköy Metro durağına 5 dakika</li>
                <li>Metrobüse 12 dakika</li>
                <li>Haydarpaşa Tren Garı'na 5 dakika</li>
                <li>Sabiha Gökçen Havalimanı'na 25 dakika</li>
                <li>Atatürk Havalimanı'na 45 dakika</li>
              </ul>
            </div>

            <div className="location-item">
              <h3>Yakın Çevre</h3>
              <ul>
                <li>Kadıköy çarşı 5 dakika</li>
                <li>Bahariye, Moda, Fenerbahçe ve Kalamış'a 5 dakika</li>
                <li>Tepe Nautilis AVM'ne 5 dakika</li>
                <li>Fenerbahçe Şükrü Saracoğlu Stadyumu'na 5 dakika</li>
                <li>Meşhur Salı Pazarına 5 dakika</li>
              </ul>
            </div>

            <div className="location-item">
              <h3>Hastaneler</h3>
              <ul>
                <li>Haydarpaşa Numune Hastanesi 7 dakika</li>
                <li>Siyami Ersek Kalp Hastanesi 7 dakika</li>
                <li>Gata Askeri Hastanesi 7 dakika</li>
                <li>Marmara Araştırma Hastanesi 10 dakika</li>
                <li>Dünya Göz Hastanesi 15 dakika</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default AboutScreen; 