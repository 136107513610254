import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import { FiInstagram } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import styled from "styled-components";
import data from "../services";
import "./HeaderToggle.css";

const MenuContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 999;
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transition: opacity 0.3s, visibility 0.3s;
`;

const HeaderToggle = ({ headerRefIndex }) => {
  const pathname = window.location.pathname;

  const [isOpen, setIsOpen] = useState(false);
  const [selected, setSelected] = useState(
    pathname === "/odalar" || pathname === "/oda-detay"
      ? 1
      : pathname === "/iletisim"
      ? 4
      : pathname === "/hakkimizda"
      ? 2
      : 0
  );
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="toggle-container">
      <div className="toggle-row">
        <div className="toggle-name-row">
          <h1 className="toggle-hotel-name">TİMYA</h1>
          <h3 className="toggle-hotel">Otel</h3>
        </div>
        <FaBars onClick={toggleMenu} fontSize={"40px"} color="white" />
      </div>
      <div>
        <MenuContainer isOpen={isOpen}>
          <div className="toggle-icon-row">
            <IoIosClose onClick={toggleMenu} fontSize={"50px"} />
          </div>
          {data.tabs.map((item, index) => (
            <div className="toggle-title-row" key={index}>
              <p
                className={
                  selected === index ? "toggle-title-selected" : "toggle-title"
                }
                onClick={() => {
                  if (index === 1) {
                    window.location.href = "/odalar";
                  } else if (index === 4) {
                    window.location.href = "/iletisim";
                  } else if (index === 2) {
                    window.location.href = "/hakkimizda";
                  } else if (index === 3) {
                    if (pathname !== "/") {
                      window.location.href = "/?scroll=gallery";
                    } else {
                      headerRefIndex(3);
                    }
                  } else {
                    headerRefIndex(index ? index : 0);
                  }
                  setSelected(index);
                  setIsOpen(!isOpen);
                }}
              >
                {item}
              </p>
            </div>
          ))}
          <div className="social-icon-row">
            <FiInstagram
              className="icon"
              onClick={() =>
                window.open(
                  "https://www.instagram.com/timyahotel?igsh=MXR1M2JmeDhtMW5nNw==",
                  "_blank"
                )
              }
              style={{ cursor: "pointer" }}
            />
          </div>
        </MenuContainer>
      </div>
    </div>
  );
};

export default HeaderToggle;
