import React, { useRef, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import data from "../../components/services";
import "./Images.css";

const Images = () => {
  const screenWidth = window.innerWidth;
  const sliderRef = useRef(null);
  const modalSliderRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: screenWidth < 909 ? 2 : 3,
    slidesToScroll: 1,
  };

  const settingsModal = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleNext = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
    if (modalSliderRef.current) {
      modalSliderRef.current.slickNext();
    }
  };

  const handlePrev = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
    if (modalSliderRef.current) {
      modalSliderRef.current.slickPrev();
    }
  };

  const openModal = (index) => {
    setSelectedImage(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedImage(null);
  };
  return (
    <div>
      <div className="image-row">
        <Slider ref={sliderRef} {...settings} arrows={false}>
          {data.images.map((imageUrl, index) => (
            <div key={index} onClick={() => openModal(index)}>
              <img
                src={imageUrl}
                alt={`slide-${index}`}
                className="image"
                style={{ width: "95%" }}
              />
            </div>
          ))}
        </Slider>

        <div className="button-row">
          <div className="left-button">
            <IoIosArrowBack
              fontSize={screenWidth < 909 ? "47px" : "58px"}
              onClick={handlePrev}
            />
          </div>

          <div className="right-button">
            <IoIosArrowForward
              fontSize={screenWidth < 909 ? "47px" : "58px"}
              onClick={handleNext}
            />
          </div>
        </div>
      </div>
      {modalOpen && (
        <div>
          <div className="modal">
            {screenWidth > 519 && (
              <div className="modal-button">
                <IoIosArrowBack
                  fontSize={screenWidth < 909 ? "47px" : "58px"}
                  onClick={handlePrev}
                />
              </div>
            )}
            <span className="modal-close" onClick={closeModal}>
              &times;
            </span>
            <div className="modal-content">
              <Slider
                ref={modalSliderRef}
                {...settingsModal}
                arrows={false}
                initialSlide={selectedImage}
              >
                {data.images.map((imageUrl, index) => (
                  <div key={index}>
                    <img
                      src={imageUrl}
                      alt={`slide-${index}`}
                      className="modal-image"
                    />
                  </div>
                ))}
              </Slider>
            </div>
            {screenWidth > 519 && (
              <div className="modal-button">
                <IoIosArrowForward
                  fontSize={screenWidth < 909 ? "47px" : "58px"}
                  onClick={handleNext}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Images;
