import otel1 from "../../assets/images/genel/otel1.jpg";
import otel10 from "../../assets/images/genel/otel10.jpg";
import otel11 from "../../assets/images/genel/otel11.jpg";
import otel12 from "../../assets/images/genel/otel12.jpg";
import otel13 from "../../assets/images/genel/otel13.jpg";
import otel14 from "../../assets/images/genel/otel14.jpg";
import otel15 from "../../assets/images/genel/otel15.jpg";
import otel16 from "../../assets/images/genel/otel16.jpg";
import otel17 from "../../assets/images/genel/otel17.jpg";
import otel18 from "../../assets/images/genel/otel18.jpg";
import otel4 from "../../assets/images/genel/otel4.jpg";
import otel5 from "../../assets/images/genel/otel5.jpg";
import otel6 from "../../assets/images/genel/otel6.jpg";
import otel7 from "../../assets/images/genel/otel7.jpg";
import otel8 from "../../assets/images/genel/otel8.jpg";
import otel9 from "../../assets/images/genel/otel9.jpg";


import tek1 from "../../assets/images/oda1/tek1.jpg";
import tek10 from "../../assets/images/oda1/tek10.jpg";
import tek11 from "../../assets/images/oda1/tek11.jpg";
import tek12 from "../../assets/images/oda1/tek12.jpg";
import tek2 from "../../assets/images/oda1/tek2.jpg";
import tek3 from "../../assets/images/oda1/tek3.jpg";
import tek4 from "../../assets/images/oda1/tek4.jpg";
import tek5 from "../../assets/images/oda1/tek5.jpg";
import tek6 from "../../assets/images/oda1/tek6.jpg";
import tek7 from "../../assets/images/oda1/tek7.jpg";
import tek8 from "../../assets/images/oda1/tek8.jpg";
import tek9 from "../../assets/images/oda1/tek9.jpg";



import cift1 from "../../assets/images/oda2/cift1.jpg";
import cift10 from "../../assets/images/oda2/cift10.jpg";
import cift11 from "../../assets/images/oda2/cift11.jpg";
import cift12 from "../../assets/images/oda2/cift12.jpg";
import cift13 from "../../assets/images/oda2/cift13.jpg";
import cift14 from "../../assets/images/oda2/cift14.jpg";
import cift15 from "../../assets/images/oda2/cift15.jpg";
import cift2 from "../../assets/images/oda2/cift2.jpg";
import cift3 from "../../assets/images/oda2/cift3.jpg";
import cift4 from "../../assets/images/oda2/cift4.jpg";
import cift5 from "../../assets/images/oda2/cift5.jpg";
import cift6 from "../../assets/images/oda2/cift6.jpg";
import cift7 from "../../assets/images/oda2/cift7.jpg";
import cift8 from "../../assets/images/oda2/cift8.jpg";
import cift9 from "../../assets/images/oda2/cift9.jpg";

import twinbed1 from "../../assets/images/oda3/twinbed1.jpg";
import twinbed2 from "../../assets/images/oda3/twinbed2.jpg";
import twinbed3 from "../../assets/images/oda3/twinbed3.jpg";
import twinbed4 from "../../assets/images/oda3/twinbed4.jpg";
import twinbed5 from "../../assets/images/oda3/twinbed5.jpg";
import twinbed6 from "../../assets/images/oda3/twinbed6.jpg";
import twinbed7 from "../../assets/images/oda3/twinbed7.jpg";
import twinbed8 from "../../assets/images/oda3/twinbed8.jpg";
import twinbed9 from "../../assets/images/oda3/twinbed9.jpg";

import oda1 from "../../assets/images/oda4/oda1.jpg";
import oda2 from "../../assets/images/oda4/oda2.jpg";
import oda3 from "../../assets/images/oda4/oda3.jpg";
import oda4 from "../../assets/images/oda4/oda4.jpg";
import oda5 from "../../assets/images/oda4/oda5.jpg";
import oda6 from "../../assets/images/oda4/oda6.jpg";
import oda9 from "../../assets/images/oda4/oda9.jpg";

import teras1 from "../../assets/images/teras/teras1.jpg";
import teras10 from "../../assets/images/teras/teras10.jpg";
import teras11 from "../../assets/images/teras/teras11.jpg";
import teras12 from "../../assets/images/teras/teras12.jpg";
import teras16 from "../../assets/images/teras/teras16.jpg";
import teras2 from "../../assets/images/teras/teras2.jpg";
import teras3 from "../../assets/images/teras/teras3.jpg";
import teras4 from "../../assets/images/teras/teras4.jpg";
import teras5 from "../../assets/images/teras/teras5.jpg";
import teras6 from "../../assets/images/teras/teras6.jpg";
import teras7 from "../../assets/images/teras/teras7.jpg";
import teras8 from "../../assets/images/teras/teras8.jpg";
import teras9 from "../../assets/images/teras/teras9.jpg";



const data = {
  tabs: ["Anasayfa", "Odalarımız", "Hakkımızda", "Galeri", "İletişim"],
  contact: {
    address: "Rıhtım Caddesi, Kırmızı Kuşak Sokak, No:3, Kadıköy/İSTANBUL",
    telephone: "0216 550 90 03",
    email: "info@kadikoytimyaotel.com",
  },
  images: [otel13, otel14,otel1, otel4, otel5, otel6, otel7, otel8, otel9, otel10, otel11, otel12, otel15, otel16, otel17, otel18,cift5,cift10,twinbed2,twinbed6,oda5,oda9,teras1,teras5,teras6,teras16],
  explanation:
    "Kadıköy otel sektöründe 2011 yılından bu yana kesintisiz hizmet veren Timya Otel, müşteri memnuniyetini, mükemmeliyetçi üslubu ile en üst düzeye taşımayı başarmıştır. Güler yüzlü hizmeti, konforlu odaları, şık ve çağdaş görünümdeki restore edilmiş binası, ışıklandırma sistemleri, İstanbul Boğazı’nın eşsiz manzarası ile buluşarak, Kadıköy otel piyasasında kendisine benzersiz bir ye edinmiştir.",
  rooms: [
    {
      image: [tek1, tek2, tek3, tek4, tek5, tek6, tek7, tek8, tek9, tek10, tek11, tek12],
      title: "Tek Kişilik Oda",
      roomType: "(Tekli Yatak Oda)",
      expectation: "Tek",
      features: [
        "50” LCD TV",
        "Fiber Hızında Internet",
        "Mini-bar",
        "Tam Donanım Banyo",
        "Klima",
        "Havalandırma",
        "Ses Geçirmeyen Cam",
      ],
      aspect: "left",
      explanationRoom:
        "Tek kişilik odalarımız bireysel misafirlerimiz tarafından en çok tercih edilen odadır. Normal odalarımızda bulunan Banyo, Wi-Fi, Global Uydu, LCD TV gibi hizmetlerden faydalanabilir.Single odalarımızda genellikle istenilen uyandırma servisi ücretsiz olarak verilmektedir.",

      display:
        "Tek kişilik odalar genellikle iş seyahati yapan misafirlerimiz tarafından tercih edilmektedir.",
    },
    {
      image: [cift1, cift2, cift3, cift4, cift5, cift6, cift7, cift8, cift9, cift10, cift11, cift12, cift13, cift14, cift15],
      title: "Çift Kişilik Oda",
      roomType: "(Çift Kişilik Büyük Yatak)",
      expectation: "Çift",
      features: [
        "50” LCD TV",
        "Fiber Hızında Internet",
        "Mini-bar",
        "Tam Donanım Banyo",
        "Klima",
        "Havalandırma",
        "Ses Geçirmeyen Cam",
      ],
      aspect: "right",
      explanationRoom:
        "Double odalar genellikle aile ve çift misafirlerimiz tarafından tercih edilmektedir. Double odalarımızdada normal odalarımızda bulunan ücretsiz Wi-Fi, Telefon, Saç Kurutma Makinası, Mini Bar, Sıcak Su ve Servis Hizmetlerimiz geçerlidir. Siz değerli misafirlerimiz için odalarımızı son derece ferah ve hoş tutmaya çalışmak en büyük görevimizdir.Otelimizde toplam olarak 11 double oda bulunmaktadır. Odalarımızın hepsinin ayrı bir dekoru ayrı bir atmosferi vardır. İsteğe özel misafirlerimize göre özel günler için dizayn edilebilir ve tekrardan dekore edilebilir.",
      display:
        "Double odalar genellikle aile ve çift misafirlerimiz tarafından tercih edilmektedir. Double odalarımızdada normal odalarımızda bulunan ücretsiz Wi-Fi, Telefon, Saç Kurutma Makinası, Mini Bar, Sıcak Su ve Servis Hizmetlerimiz geçerlidir.",
    },
    {
      image: [twinbed1, twinbed2, twinbed3, twinbed4, twinbed5, twinbed6, twinbed7, twinbed8, twinbed9, oda1, oda2, oda3, oda4, oda5, oda6],
      title: "Twinbed Oda ",
      roomType: "( İki Tane Tekli Yataklı Oda )",
      expectation: "Çift",
      features: [
        "50” LCD TV",
        "Fiber Hızında Internet",
        "Mini-bar",
        "Tam Donanım Banyo",
        "Klima",
        "Havalandırma",
        "Ses Geçirmeyen Cam",
      ],
      aspect: "left",
      explanationRoom:
        "Twinbed oda bir oda içerisinde iki adet tekli yatak olan oda şeklidir. Genellikle arkadaşların tercih ettiği ve en çok satan oda biçiminde yer alır. Twinbed odalarımız otelimizin birinci katında bir tane, ikinci katında bir tane, üçüncü katında iki tane ve dördüncü katında iki tane olmak üzere altı tane twinbed odamız mevcuttur.Twinbed odalar içerisinde LCD Televizyon, Global Yayın Kanalları Dahil Uydu, Klima, Duş, Saç Kurutma Makinesi, 2 x Tekli Yatak, Aynalı Komidin, Dolap ve klasik ekipmanlar barındırmaktadır. Odalarımız müşterilerimize özel olarak temizlenip teslim edilir.",
      display:
        "Twinbed oda bir oda içerisinde iki adet tekli yatak olan oda şeklidir. Genellikle arkadaşların tercih ettiği ve en çok satan oda biçiminde yer alır.",
    },
    {
      image: [teras1, teras2, teras3, teras4, teras5, teras6, teras7, teras8, teras9, teras10, teras11, teras12],
      title: "Teras Oda ",
      roomType: "( Bir Adet Çift Kişilik, Bir Adet Tek Kişilik Yataklı Oda)",
      expectation: "Çift",
      features: [
        "Teras",
        "50” LCD TV",
        "Fiber Hızında Internet",
        "Mini-bar",
        "Tam Donanım Banyo",
        "Klima",
        "Havalandırma",
        "Ses Geçirmeyen Cam",
      ],
      aspect: "right",
      explanationRoom:
        "Teras odamızda genellikle siz özel misafirlerimiz için özel olarak dizayn edilmiştir. Teras odalarımızda diğer odalarımıza nazaran daha geniş bir alan ve ek hizmetlerimiz mevcuttur.Teras odalarımız içerisinde daha yüksek derece havalandırma, çift kişilik yatak mevcuttur. Misafirlerimiz için planlanan bu odalarımızda LCD TV, Universal Uydu, Mini Bar ve Kablosuz Yüksek Band İnternet mevcuttur.",
      display:
        "Teras odamızda genellikle siz özel misafirlerimiz için özel olarak dizayn edilmiştir. Teras odalarımızda diğer odalarımıza nazaran daha geniş bir alan ve ek hizmetlerimiz mevcuttur.",
    },
  ],
};

export default data;
