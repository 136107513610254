import React, { useCallback, useEffect, useMemo, useRef } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import otel16 from "../../assets/otel16.jpg";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import HeaderToggle from "../../components/headerToggle/HeaderToggle";
import Images from "../../components/images/Images";
import Rooms from "../../components/rooms/Rooms";
import data from "../../components/services";

import "./Home.css";
function Home() {
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();

  const imageRefs = useMemo(() => [ref1, ref2, ref3, ref4, ref5], []);

  const screenWidth = window.innerWidth;

  const scrollToImage = useCallback((index) => {
    if (imageRefs[index].current) {
      imageRefs[index].current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [imageRefs]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('scroll') === 'gallery') {
      scrollToImage(3);
      window.history.replaceState({}, document.title, "/");
    }
  }, [scrollToImage]);

  return (
    <div className="App">
      <div>
        {screenWidth < 910 ? (
          <HeaderToggle headerRefIndex={scrollToImage} />
        ) : (
          <Header headerRefIndex={scrollToImage} />
        )}
      </div>
      <div className="flex" ref={imageRefs[0]}>
        <img src={otel16} className="home-image" alt="" />
      </div>
      <div className="home-background">
        <div className="explanation-row">
          <h2 className="title">TİMYA HAKKINDA</h2>
          <p className="hotel-explanation">{data.explanation}</p>
        </div>
        <h2 className="title">ODALARIMIZ</h2>
        <div>
          <Rooms />
        </div>

        <div ref={imageRefs[3]}>
          <Images />
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Home;
